import FirstScreen from "~/components/Enga/Components/FirstScreen";
import AutopromoContainer from "~/components/Enga/Components/AutopromoContainer";
import imgProxy from "~/utils/imgproxy";
import { urlRs } from "~/utils/url";
import { useViewportSizeContext } from "~/contexts/ViewportSizeContext";
import { Show } from "solid-js";

import "./EngaPages.css";
import "./EngaSinglePage.css";

import IconArrowForward from "~/img/icons/arrow_forward.svg";

export default function Enga2Page() {
  return (
    <>
      <article class="page-engagement page-engagement-2">
        <FirstScreen
          visual="/images/engagements/page-engagement-2-visual.jpg"
          visualMobile="/images/engagements/page-engagement-2-visual-mobile.jpg"
          title="Le confort d’été"
          prevLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/une-meilleure-qualite-de-l-air.html"
          nextLink="/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-amenagements-intelligents.html"
        />

        <EngaDetails />
        <section class="engagement-rows">
          <h2>Les preuves de nos engagements</h2>
          <EngaRow1 />
          <EngaRow2 />
        </section>

        <section class="next-nav">
          <a
            href={urlRs(
              "enga",
              "/cogedim-pour-vous/pourquoi-choisir-cogedim/nos-engagements/des-amenagements-intelligents.html",
            )}
            class="btn"
          >
            Voir l'engagement suivant{" "}
            <i aria-hidden="true" class="cog-icon">
              <IconArrowForward />
            </i>
          </a>
        </section>
      </article>

      <AutopromoContainer />
    </>
  );
}

function EngaDetails() {
  const [viewportSizeProvider] = useViewportSizeContext();

  return (
    <>
      <section class="engagement-details">
        <div class="introduction">
          <div class="tags">
            <span>Pour votre bien être</span>
          </div>
          <p>
            Tous nos logements bénéficient d’une{" "}
            <strong>conception optimisée en matière de confort d’été</strong>{" "}
            pour limiter la sensation de chaleur.
          </p>
        </div>

        <div class="media-part infography">
          <Show
            when={viewportSizeProvider.viewPortIsLessThan768}
            fallback={
              <img
                src={imgProxy(
                  "/images/engagements/page-engagement-2-infographie-desktop.jpg",
                  `size:700:2679/resizing_type:fill`,
                )}
                alt=""
                height="2679"
                width="700"
                loading="lazy"
              />
            }
          >
            <img
              src={imgProxy(
                "/images/engagements/page-engagement-2-infographie-mobile.jpg",
                `size:750:4994/resizing_type:fill`,
              )}
              alt=""
              height="4994"
              width="750"
              loading="lazy"
            />
          </Show>
        </div>
      </section>
    </>
  );
}

function EngaRow1() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>Une limitation de la sensation de chaleur dans les logements</h3>
          <p>
            Nous concevons dès à présent des logements plus résilients vis-à-vis
            du réchauffement climatique, sans attendre les nouvelles
            réglementations énergétiques.
          </p>
          <p>
            Cette démarche suppose d’intégrer dès la conception des projets, des
            bâtiments comme de leurs abords, des solutions statiques améliorant
            parfois de manière significative le confort des habitants à
            l’intérieur de leurs logements&nbsp;:
          </p>
          <ul>
            <li>
              une conception intérieure des appartements&nbsp;: appartements
              traversants ou bi-orientés privilégiés, lave-linge dans la
              buanderie ou dans la salle de bains pour éviter la surchauffe des
              séjours lorsque la cuisine est ouverte&nbsp;;
            </li>
            <li>
              quand les règles d’urbanisme le permettent, une orientation des
              bâtiments optimale.
            </li>
          </ul>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-2-visuel1.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}

function EngaRow2() {
  return (
    <>
      <div class="engagement-row">
        <div class="text-part">
          <h3>L'influence des aménagements extérieurs</h3>
          <p>
            Les espaces extérieurs communs et privatifs de leur résidence jouent
            également un rôle dans la régulation de la sensation de chaleur,
            avec notamment&nbsp;:
          </p>
          <ul>
            <li>des terrasses végétalisées&nbsp;;</li>
            <li>
              des espaces extérieurs travaillés et végétalisés pour éviter les
              ilots de chaleur, et apporter de la fraîcheur aux habitants&nbsp;;
            </li>
            <li>
              un bâtiment aux teintes claires, avec des protections solaires
              adaptées&nbsp;: débords de balcons, brises soleil, panneaux
              verticaux.
            </li>
          </ul>
        </div>
        <picture class="visual-part full">
          <img
            src={imgProxy(
              "/images/engagements/page-engagement-2-visuel2.jpg",
              `size:960:1080/resizing_type:fill`,
            )}
            alt=""
            height="422"
            width="375"
            loading="lazy"
          />
        </picture>
      </div>
    </>
  );
}
